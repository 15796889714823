/*  HEADER  */
.topspace {
  height: 5vh;
}

.header {
  z-index: 2;
  position: fixed;
  background: black;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: auto;
}

.headerright,
.headerleft {
  display: flex;
  align-items: center;
}
#kids,
#bell,
#blunt {
  display: inline-flex;
}

#kids,
#bell {
  width: 2em;
}
#blunt {
  width: 3em;
}
#logo {
  width: 8em;
}

/*  BODY  */

.all {
  padding: 3%;
  background: black;
}

h6,
h5,
h4,
h2,
h1 {
  margin: auto;
  color: white;
}

/* PURE REACT CAROUSEL sliders */

.cards {
  position: relative;
}

.sliderbuttonleft {
  position: relative;
  z-index: 1;
}

.sliderbuttonright {
  position: relative;
  z-index: 1;
}

.buttonleft {
  border: none;
  position: absolute;
  background-color: rgba(40, 40, 40, 0.45);
  height: 20vh;
  width: 4.5%;
  left: 0;
  top: 0;
}

.buttonright {
  border: none;
  position: absolute;
  background-color: rgba(65, 12, 12, 0.45);
  height: 20vh;
  width: 4.5%;
  right: 0;
  top: 0;
}

/* Extra small devices (phones, 400px and down) */
@media only screen and (max-width: 400px) {
  .buttonleft,
  .buttonright {
    width: 4.5%;
    height: 14vh;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 750px) {
  #blunt {
    width: 5vw;
    height: 95%;
  }
  #logo {
    width: 12vw;
    height: 70%;
    padding: 1vh;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .buttonleft,
  .buttonright {
    width: 4.5%;
    height: 18vh;
  }
}

/* Extra large devices (large laptops and desktops, 2000px and up) */
@media only screen and (min-width: 2000px) {
  .buttonleft,
  .buttonright {
    width: 3%;
    height: 10vh;
  }
}

/* FOOTER */

.footer-wrap {
  background-color: #191919;
  display: flex;
  justify-content: center;
  height: 25vh;
}

ul,
li {
  list-style-type: none;
}

.footer-info {
  color: white;
  flex-direction: column;
}

.footer-info-container {
  display: flex;
}

.footer-icons {
  color: white;
}
